import React from 'react'
import {Link} from 'gatsby'
import logo from "../../assets/images/OUTSPOKE_LOGO-2019_WEB-white.png"
import footerMap from "../../assets/images/footer-map.png"

const Footer = () => {

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-color">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <a href="/" className="logo">
                                <img src={logo} alt="logo" style={{width: 280, position: 'relative', right: 16}} />
                            </a>
                            <p>Outspoke is a supply management platform designed to connect and
                                empower the cannabis industry.</p>

                            <ul className="social-link">
                                <li>
                                    <Link className="d-block" to="https://www.facebook.com/outspoke.io" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-facebook'></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="#" className="d-block" to="https://twitter.com/outspoke_io" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-twitter'></i>
                                    </Link>
                                </li>
                                {/*<li>
                                    <Link to="#" className="d-block" to="https://www.instagram.com/outspoke" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-instagram'></i>
                                    </Link>
                                </li>*/}
                                <li>
                                    <Link to="#" className="d-block" to="https://www.linkedin.com/company/outspoke-io/" target="_blank" rel="noreferrer">
                                        <i className='bx bxl-linkedin'></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Explore</h3>

                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/">
                                        Home
                                    </Link>
                                </li>
                                  <li>
                                    <Link to="/about-us">
                                        About Us
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/cannabis">
                                        Cannabis
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/blog">
                                        Our Blog
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Resources</h3>

                            <ul className="footer-links-list">
                                <li>
                                    <Link to="/faq">
                                        FAQs
                                    </Link>
                                </li>
                                {/*<li>
                                    <Link to="/pricing">
                                        Pricing
                                    </Link>
                                </li>*/}
                                <li>
                                    <Link to="https://b1.outspoke.io/signup">
                                        Sign Up
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://b1.outspoke.io">
                                        Login
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Address</h3>

                            <ul className="footer-contact-info">
                                <li>
                                    <i className='bx bx-map'></i>
                                    8 The Green, Suite A, Dover, DE 19901, <br /> United States
                                </li>
                                <li>
                                    <i className='bx bx-envelope'></i>
                                    <a href="info@beoutspoke.com">info@beoutspoke.com</a>
                                </li>
                                {/*<li>
                                    <i className='bx bxs-inbox'></i>
                                    <a href="tel:+557854578964">+55 785 4578964</a>
                                </li>*/}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="footer-bottom-area">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <p>Copyright @{currentYear} <strong>Outspoke</strong> All rights reserved</p>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <ul>
                                <li>
                                    <Link to="/privacy-policy">
                                        Privacy Policy
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/terms-of-service">
                                        Terms & Conditions
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-map">
                <img src={footerMap} alt="footer-logo" />
            </div>
        </footer>
    );
}

export default Footer;
