import React from 'react'
import { Helmet } from "react-helmet"

const SEO = () => {
    return (
        <div>
            <Helmet>
                <title>Outspoke - The Hub & Spoke of Sustainable Supply Chains</title>
                <meta name="description" content="Outspoke - The Hub & Spoke of Sustainable Supply Chains" />
                <meta name="og:title" property="og:title" content="Outspoke - The Hub & Spoke of Sustainable Supply Chains"></meta>
                <meta name="twitter:card" content="Outspoke - The Hub & Spoke of Sustainable Supply Chains"></meta>
                <link rel="canonical" href="https://outspoke.io/"></link>
                <link rel="icon" href="https://s3.us-west-1.amazonaws.com/outspoke-images/new_os_favi.png"></link>
                <meta property="og:image" content="https://res.cloudinary.com/dev-empty/image/upload/v1593069801/explore-learning.jpg" />
            </Helmet>
        </div>
    )
}

export default SEO
